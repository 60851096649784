import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const Name = Styled.span`
  display: block;
  font-weight: 500;
  font-size: ${18/16}rem;
  @media screen and (min-width: 576px) {  
    font-size: ${21/16}rem;
  }
`;
const Email = Styled(Name)`
  font-weight: 300;
`;
const Title = Styled.span`
  display: inline;
  font-family: 'Libre Baskerville';
  font-size: ${18/16}rem;
  font-style: italic;
`;
export const Container = Styled.div`
  margin-bottom: ${29/16}rem;
  @media screen and (min-width: 576px) {  
    min-width: ${315/16}rem;
  }
`;
const Contact = ({ name, email, title }) => (
  <Container>
    <a href={`mailto:${email}`}>
      <Name>{title ? <Title>{title} </Title>: null}{ name }</Name>
      <Email>{ email }</Email>
    </a>
  </Container>
);

Contact.defaultProps = {
  title: null,
}
Contact.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Contact;
