import React from "react";
import Styled from "styled-components";

import Layout from "../components/layout";
import { Container, Title } from "../components/page-styles";
import Contact, { Container as StaffCont } from "../components/staff-contact";

const StyledWrapper = Styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 949px) {
    ${Title} {
      text-align: center;
    }
    > div {
      align-items: center;
      width: 100%;
    }
    ${StaffCont} {
      width: 100%;
      > a {
        text-align: center;
      }
    }
  }
`;
const StyledInner = Styled.div`
  margin-bottom: ${45 / 16}rem;
`;
const StaffDirectoryPage = () => (
  <Layout>
    <Container>
      <Title>Film/Television/Theatre</Title>
      <StyledWrapper>
        <StyledInner>
          <Contact
            title="Agent"
            name="Penny Noble"
            email="pnoble@ncatalent.com"
          />
          <Contact
            title="Assistant"
            name="Michael O'Hara"
            email="mohara@ncatalent.com"
          />
        </StyledInner>
        <StyledInner>
          <Contact
            title="Agent"
            name="Rich Caplan"
            email="rcaplan@ncatalent.com"
          />
          <Contact
            title="Assistant"
            name="Jackie Lyon"
            email="jlyon@ncatalent.com"
          />
        </StyledInner>
      </StyledWrapper>

      <StyledWrapper>
        <StyledInner>
          <Contact
            title="Agent"
            name="Norbert Abrams"
            email="nabrams@ncatalent.com"
          />
          <Contact
            title="Associate"
            name="Sav Murthy"
            email="sav@ncatalent.com"
          />
        </StyledInner>
        
        <StyledInner>
          <Contact
            title="Agent"
            name="Michael A. Marino"
            email="mmarino@ncatalent.com"
          />
          <Contact
            title="Assistant"
            name="Kordell Medas"
            email="kmedas@ncatalent.com"
          />
        </StyledInner>

        <StyledInner>
        <Contact
            title="Agency Associate"
            name="Andrew Rosentsveig"
            email="arosentsveig@ncatalent.com"
          />
        </StyledInner>
      </StyledWrapper>

      <StyledWrapper>
        <StyledInner>
          <Title>Commercials</Title>
          <Contact
            title="Agent"
            name="Rick Gerrits"
            email="rgerrits@ncatalent.com"
          />
          {/* <Contact
            title="Assistant"
            name="Josh Zimel"
            email="jzimel@ncatalent.com"
          /> */}
        </StyledInner>
        <StyledInner>
          <Title>Voice & Animation</Title>
          <Contact
            title="Agent"
            name="James Crammond"
            email="james.crammond@ncatalent.com"
          />
          <Contact
            title="Assistant"
            name="Josh Zimel"
            email="jzimel@ncatalent.com"
          />
        </StyledInner>
      </StyledWrapper>

      <StyledWrapper>
      <StyledInner>
          <Title>Accounting</Title>
          <Contact name="Khushi Paul" email="kpaul@ncatalent.com" />
          <Contact name="Parbaty Paul" email="ppaul@ncatalent.com" />
        </StyledInner>
        <StyledInner>
          <Title>Reception</Title>
          <Contact
            name="Jennifer Gaynor"
            email="jgaynor@ncatalent.com"
          />
        </StyledInner>
      </StyledWrapper>

      <StyledWrapper>
      <StyledInner>
          <Title>Studio</Title>
          <Contact
            name="Self-tapes / Voice Recordings"
            email="studio@ncatalent.com"
          />
        </StyledInner>
        {/*<StyledInner>*/}
        {/*  <Title>Reception</Title>*/}
        {/*  <Contact name="Jennifer Gaynor" email="jgaynor@ncatalent.com" />*/}
        {/*</StyledInner>*/}
      </StyledWrapper>
    </Container>
  </Layout>
);

export default StaffDirectoryPage;
