import Styled from "styled-components";

export const Paragraph = Styled.p`
  @media screen and (min-width: 576px) {
    line-height: ${24 / 18};
    font-size: ${18 / 16}rem;
  }
`;
export const Title = Styled.h1`
  text-transform: uppercase;
  font-size: ${24 / 16}rem;
  margin: 0 ${28 / 16}rem ${39 / 16}rem;
  text-align: center;
  @media screen and (min-width: 949px) {  
    text-align: left;
    margin: 0;
    margin-bottom: ${39 / 16}rem;
  }
  @media screen and (min-width: 768px) {
  }
`;
export const SubTitle = Styled.h2`
  font-family: 'Libre Baskerville';
  font-weight: 300;
  font-size: ${21 / 16}em;
  font-style: italic;
  margin-top: ${39 / 16}rem;
  margin-bottom: ${30 / 16}rem;
  ${Title} + & {
    margin-top: 0;
  }
`;
export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  padding: 0 ${25 / 16}rem;
  @media screen and (min-width: 576px) {  
    padding-top: ${35 / 16}rem;
  }
  @media screen and (min-width: 768px) {
    padding-top: ${55 / 16}rem;
  }
  @media screen and (min-width: 992px) {
    padding-top: ${94 / 16}rem;
    padding-left: ${50 / 16}rem;
  }
`;

export const Fancy = Styled.span`
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 300;
  display: inline-block;
  padding: 0 ${3 / 16}rem;
`;
